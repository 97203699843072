import { usePathname } from "next/navigation"
import { useLayoutStore, useBreakpointStore } from "@/store/layoutStore"
import Link from "next/link"
import React from "react"

export interface Link {
  name: string
  href: string
  icon?: React.ElementType
  activeIcon?: React.ElementType
}

export default function ALink({ name, href, icon, activeIcon }: Link) {
  const pathname = usePathname()
  const isMobile = useBreakpointStore(state => state.isMobile)
  const toggleLeft = useLayoutStore(state => state.toggleLeft)
  const Icon = icon || null
  const ActiveIcon = activeIcon || null
  const isActive = pathname === href

  const closeLeft = () => {
    if (isMobile) {
      toggleLeft()
    }
  }

  return (
    <Link
      href={href}
      className={`flex h-12 items-center gap-2 rounded-lg px-3 font-medium transition-all ${isActive && ActiveIcon ? "bg-primary-lighten text-primary" : ""} ${isActive && !ActiveIcon ? "!font-semibold text-gray-11" : ""} ${!isActive && !ActiveIcon ? "opacity-50" : ""} `}
      onClick={closeLeft}
    >
      {isActive && <>{ActiveIcon ? <ActiveIcon /> : <div className="w-6" />}</>}
      {!isActive && <>{Icon ? <Icon /> : <div className="w-6" />}</>}
      <div>{name}</div>
    </Link>
  )
}
