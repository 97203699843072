import { useEffect, useState } from "react"
import { useProfileStore } from "@/store/profileStore"
import { useSubscriptionStore, usePoliciesStore } from "@/store/subscriptionStore"
import { startFreeTrial } from "@/app/api/billing/api"
import { loadTossPayments } from "@tosspayments/payment-sdk"
import { workspaceId } from "@/app/api/config"
import { useLayoutStore } from "@/store/layoutStore"

import Confirmation from "@/components/Confirmation/Confirmation"
import PlanModal from "@/components/Subscription/PlanModal"
import Modal from "@/components/Modal/Modal"
import { Button } from "@nextui-org/react"
import Image from "next/image"
import { BoltaEvent } from "@/utils/mixpanels"

export default function SubscriptionModals() {
  const [needAdminConfirm, setNeedAdminConfirm] = useState(false)
  const [trialModalOpen, setTrialModalOpen] = useState(false)
  const [planModalOpen, setPlanModalOpen] = useState(false)
  const [planModal2Open, setPlanModal2Open] = useState(false)
  const profile = useProfileStore(state => state.profile)
  const subscriptionInfo = useSubscriptionStore(state => state.subscriptionInfo)
  const policies = usePoliciesStore(state => state.policies)
  const authorizedFeatures = useProfileStore.getState().getAuthorizedFeatures()
  const isCardChanged = useLayoutStore(state => state.isCardChanged)

  const check1 = () => {
    // 플랜변경 권한 없을 때: 관리자 확인 필요
    if (
      !authorizedFeatures.includes("BILLING_MANAGEMENT") &&
      !subscriptionInfo?.subscription
    ) {
      setNeedAdminConfirm(true)
    }
  }

  const check2 = () => {
    // 플랜변경 권한 있고 구독없을 때: 무료체험 시작하기
    if (
      authorizedFeatures.includes("BILLING_MANAGEMENT") &&
      !subscriptionInfo?.subscription
    ) {
      setTrialModalOpen(true)
    }
  }

  const check3 = () => {
    // 무료체험 중이고, 무료체험이 만료되면: 플랜 선택
    if (
      subscriptionInfo?.subscription?.isFreeTrial &&
      subscriptionInfo?.subscription?.expiresAt < new Date().getTime()
    ) {
      setPlanModalOpen(true)
    }
  }

  const check4 = () => {
    // 정기결제 실패시: 플랜 선택
    if (subscriptionInfo?.subscription?.blockStatus === "BLOCKED_BY_PAYMENT_FAILED") {
      setPlanModal2Open(true)
    }
  }

  const check5 = () => {
    // 이상 활동으로 인한 차단
    if (
      subscriptionInfo?.subscription?.blockStatus === "BLOCKED_BY_ABNORMAL_ACTIVITY"
    ) {
      window.location.href = "/ban"
    }
  }

  const handleCardChange = async () => {
    setPlanModal2Open(false)

    const tossPaymentsClientKey =
      process.env.NEXT_PUBLIC_TOSS_PAYMENTS_CLIENT_KEY ?? ""
    const tossPayments = await loadTossPayments(tossPaymentsClientKey)
    await tossPayments
      .requestBillingAuth("카드", {
        customerKey: Buffer.from(`TOSS_PG_${workspaceId}`, "binary").toString(
          "base64",
        ),
        successUrl: `${window.location.origin}/billing`,
        failUrl: `${window.location.origin}/billing/register-card/failure`,
      })
      .then(() => {
        window.location.href
      })
      .catch(function (error) {
        if (error.code === "USER_CANCEL") {
          // 결제 고객이 결제창을 닫았을 때 에러 처리
          window.location.reload()
        }
      })
  }

  useEffect(() => {
    // if (!subscriptionInfo) return
    if (!profile) return

    check1()
    check2()
    check3()
    check4()
    check5()
  }, [profile, subscriptionInfo])

  return (
    <>
      {/* 관리자 확인 필요 Confirmation */}
      {needAdminConfirm && (
        <Confirmation
          isOpen={needAdminConfirm}
          onOpenChange={setNeedAdminConfirm}
          title="관리자 확인 필요"
          variant="alert"
          hideButtons
          isDismissable={false}
        >
          워크스페이스의 관리자에게 문의하세요.
        </Confirmation>
      )}

      {/* 무료체험 시작하기 Modal */}
      {trialModalOpen && (
        <Modal
          isOpen={trialModalOpen}
          onOpenChange={setTrialModalOpen}
          scrollBehavior="inside"
          isDismissable={false}
          hideCloseButton
          classNames={{
            base: "max-w-[782px]",
            backdrop: "bg-[rgba(23,29,35,0.50)]",
          }}
          headerContent={
            <div>
              <div className="mt-1 text-2xl font-semibold">무료체험 시작하기</div>
              <div className="mt-1 text-[16px] font-normal text-gray-08">
                2주 동안 플러스 플랜의 모든 기능을 무료로 체험해보실 수 있습니다.
              </div>
            </div>
          }
          footerContent={
            <Button
              size="lg"
              color="primary"
              className="mb-2 w-full"
              onClick={async () => {
                if (!policies) {
                  return
                }
                BoltaEvent.track("clicked__start_free_trial__subscription_modal")
                startFreeTrial(policies.POLICY_PLUS.id).then(() => {
                  window.location.reload()
                })
              }}
            >
              무료체험 시작
            </Button>
          }
        >
          <div className="flex gap-5 max-sm:flex-wrap">
            <div className="md:max-w-[380px]">
              <Image
                alt="trial01"
                src="/trial01.png"
                unoptimized
                width={1000}
                height={1000}
              />
            </div>
            <div className="md:max-w-[332px]">
              <Image
                alt="trial02"
                src="/trial02.png"
                unoptimized
                width={1000}
                height={1000}
              />
            </div>
          </div>
        </Modal>
      )}

      <PlanModal
        isOpen={planModalOpen}
        onOpenChange={setPlanModalOpen}
        isDismissable={false}
        subTitle="무료체험기간이 종료되었습니다. 서비스를 계속 이용하기 위해 플랜을 선택해 주세요."
      />

      <PlanModal
        isOpen={planModal2Open}
        onOpenChange={setPlanModal2Open}
        variant="danger"
        isDismissable={false}
      >
        {!isCardChanged && (
          <div className="flex flex-wrap items-center">
            결제에 실패하였습니다. 카드 변경 혹은 플랜을 새로 결제해 주세요.
            <Button
              size="sm"
              variant="bordered"
              className="white-btn ml-auto"
              onClick={() => {
                BoltaEvent.track(
                  "clicked__change_card__payment_fail_subscription_modal",
                )
                handleCardChange()
              }}
            >
              카드 변경
            </Button>
          </div>
        )}
      </PlanModal>
    </>
  )
}
