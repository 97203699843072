import { useSnackbarStore } from "@/store/snackbarStore"
import { TooltipProps } from "@nextui-org/react"

import { Tooltip as _Tooltip } from "@nextui-org/react"
import AlertCircleIcon from "@/assets/icons/alert-circle.svg"

type Placement =
  | "top"
  | "bottom"
  | "right"
  | "left"
  | "top-start"
  | "top-end"
  | "bottom-start"
  | "bottom-end"
  | "left-start"
  | "left-end"
  | "right-start"
  | "right-end"

interface Props extends TooltipProps {
  content: string
  placement: Placement
  offset?: number
  maxWidth?: number
  isDisabled?: boolean
  isOpen?: boolean
  showArrow?: boolean
  children?: React.ReactNode
}

export default function Tooltip({
  content,
  placement,
  offset,
  maxWidth,
  isDisabled = false,
  isOpen,
  showArrow = true,
  children,
}: Props) {
  const openSnackbar = useSnackbarStore(state => state.openSnackbar)

  return (
    <>
      <_Tooltip
        content={content}
        color="foreground"
        placement={placement}
        offset={offset}
        isOpen={isOpen}
        isDisabled={isDisabled}
        showArrow={showArrow}
        classNames={{
          content: `rounded-small px-[10px] py-[6px] break-keep whitespace-pre-wrap ${
            maxWidth ? `max-w-[${maxWidth}px]` : ""
          }`,
        }}
      >
        {children ? (
          children
        ) : (
          <div>
            <AlertCircleIcon className={`ml-1 hidden md:block`} />
          </div>
        )}
      </_Tooltip>
      {children ? null : (
        <div>
          <AlertCircleIcon
            className={`ml-1 max-sm:cursor-pointer md:hidden`}
            onClick={() => openSnackbar(content, "info")}
          />
        </div>
      )}
    </>
  )
}
