/**
 * @todo 기존 "src/components/Layout/Main.tsx"에 구현된 함수를 이동한것입니다. 추후에 nextjs RoutingGroup으로 개선해야합니다.
 */
export function isAuthPath(pathname: string) {
  return (
    pathname === "/login" ||
    pathname === "/login-success" ||
    pathname === "/find-password" ||
    pathname === "/invitations" ||
    pathname.startsWith("/join") ||
    pathname.startsWith("/billing/register-card") ||
    pathname.startsWith("/print")
  )
}
