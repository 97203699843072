"use client"
import { useEffect, useRef } from "react"
import { usePathname } from "next/navigation"
import { useLayoutStore, useBreakpointStore } from "@/store/layoutStore"
import { useProfileStore } from "@/store/profileStore"
import clsx from "clsx"

const pathPermission: { [key: string]: string } = {
  "/issuance": "TAX_INVOICE_ISSUE_REQUEST",
  "/invoices": "PURCHASED_TAX_INVOICE_FETCH",
  "/request-form": "ISSUANCE_REQUEST_FORM_FETCH",
  "/customers": "TAX_INVOICE_ISSUE_REQUEST",
  "/received-invoices": "RECEIVED_TAX_INVOICE_FETCH",
  "/transactions": "BANK_ACCOUNT_TRANSACTION_FETCH",
  "/request-payment/templates": "PAYMENT_REQUEST_MANAGEMENT",
  "/request-payment/codes": "PAYMENT_REQUEST_MANAGEMENT",
  "/settings/workspace": "COMPANY_DETAIL_MANAGEMENT",
  "/settings/certification": "CERTIFICATE_MANAGEMENT",
  "/billing": "BILLING_MANAGEMENT",
  "/team/roles": "COLLABORATOR_AND_ROLE_MANAGEMENT",
}
export default function Main({ children }: { children: React.ReactNode }) {
  const authorizedFeatures = useProfileStore.getState().getAuthorizedFeatures()
  const isLeftOpen = useLayoutStore(state => state.isLeftOpen)
  const isMobile = useBreakpointStore(state => state.isMobile)
  const path = usePathname()
  const mainContentRef = useRef<HTMLDivElement>(null)
  const profile = useProfileStore(state => state.profile)

  // 라우터 변경될 때 Main 스크롤 위치 초기화, 권한 검증
  useEffect(() => {
    const needsPermission = pathPermission[path]
    if (needsPermission && !authorizedFeatures.includes(needsPermission)) {
      if (authorizedFeatures.includes("TAX_INVOICE_ISSUE_REQUEST")) {
        window.location.href = "/issuance"
      } else if (authorizedFeatures.includes("PURCHASED_TAX_INVOICE_FETCH")) {
        window.location.href = "/invoices"
      } else if (authorizedFeatures.includes("ISSUANCE_REQUEST_FORM_FETCH")) {
        window.location.href = "/request-form"
      } else if (authorizedFeatures.includes("BANK_ACCOUNT_TRANSACTION_FETCH")) {
        window.location.href = "/transactions"
      } else {
        window.location.href = "/request-payment/requests"
      }
    }
    if (mainContentRef.current) {
      mainContentRef.current.scrollTop = 0
    }
  }, [path])

  if (!profile?.workspace) {
    return <div></div>
  }

  return (
    <div
      ref={mainContentRef}
      className={clsx("main-content bg-default", {
        full: !isLeftOpen || isMobile,
      })}
    >
      {children}
    </div>
  )
}
