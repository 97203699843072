type Props = {
  icon?: React.ReactElement
  children: React.ReactNode
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

export default function MenuItem({ icon, children, onClick }: Props) {
  return (
    <div
      className="flex cursor-pointer select-none items-center rounded px-3 py-2 text-sm text-gray-10 hover:bg-[#F6F8FB]"
      onClick={onClick}
    >
      {icon && <div className="mr-2">{icon}</div>}
      {children}
    </div>
  )
}
