"use client"
import * as React from "react"
import { usePathname } from "next/navigation"
import GlobalLayout from "./GlobalLayout"
import Snackbar from "../Snackbar/Snackbar"
import { BoltaEvent } from "@/utils/mixpanels"
import { isAuthPath } from "@/features/auth"

export default function RootLayout({ children }) {
  const pathname = usePathname()
  const isAuthPage = isAuthPath(pathname)
  const isIssuanceResultPage = pathname === "/issuance-result"
  const isRequestFormPage =
    pathname.startsWith("/request/") || pathname === "/request-payment/requests/new"
  const banPage = pathname === "/ban"

  React.useEffect(() => {
    BoltaEvent.trackPageView()
  }, [pathname])

  return (
    <>
      {isAuthPage || isIssuanceResultPage || isRequestFormPage || banPage ? (
        <div className="h-[100dvh] overflow-auto">
          <Snackbar />
          {children}
        </div>
      ) : (
        <GlobalLayout>{children}</GlobalLayout>
      )}
    </>
  )
}
