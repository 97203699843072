"use client"

import {
  useBreakpointStore,
  useLayoutStore,
  useShowDataStore,
} from "@/store/layoutStore"
import clsx from "clsx"

// UI
import BottomMenu from "./components/BottomMenu"
import { Button } from "@nextui-org/react"
import { CodeBracketIcon } from "@heroicons/react/24/solid"
import Link from "next/link"
import style from "./Left.module.scss"
import NavLinks from "./components/NavLinks"
import Image from "next/image"

export default function Left() {
  const isMobile = useBreakpointStore(state => state.isMobile)
  const isLeftOpen = useLayoutStore(state => state.isLeftOpen)
  const closeLeft = useLayoutStore(state => state.closeLeft)
  const isShowData = useShowDataStore(state => state.isShowData)
  const toggleShowData = useShowDataStore(state => state.toggleShowData)

  return (
    <div>
      {isLeftOpen && isMobile && (
        <div className={style.overlay} onClick={closeLeft} />
      )}
      <div
        className={`${style.left} flex flex-col pb-6 ${clsx({
          [style.hide]: !isLeftOpen,
          "shadow-xl": isMobile,
        })}`}
      >
        <div className="p-6 pb-4">
          <Link href="/">
            <Image
              alt="Bolta logo"
              src="/bolta-bi-02.svg"
              priority
              width={117}
              height={24}
            />
          </Link>
        </div>

        {process.env.NEXT_PUBLIC_APP_ENV === "development" && (
          <div className="ml-5 pt-2 text-center text-small tracking-[20px] text-gray-08">
            SANDBOX
          </div>
        )}

        <NavLinks />

        {process.env.NEXT_PUBLIC_APP_ENV === "development" && (
          <div className="p-6 pb-4 opacity-80">
            <Button
              size="sm"
              color={isShowData ? "secondary" : "default"}
              onClick={toggleShowData}
              startContent={<CodeBracketIcon className="w-4" />}
            >
              {isShowData ? "Hide Data" : "Show Data"}
            </Button>
          </div>
        )}

        <div className="grow" />

        <BottomMenu />
      </div>
    </div>
  )
}
