import LockIcon from "@/assets/icons/locked_chip.svg"

type Props = {
  name: string
  icon: React.ElementType
  onClick?: () => void
}

export default function LockedLink({ name, onClick, icon }: Props) {
  const LinkIcon = icon

  return (
    <div
      className="my-1 flex cursor-pointer select-none items-center rounded-lg bg-[rgba(249,250,251,0.50)] p-3"
      onClick={onClick}
    >
      <div className="mr-2">
        <LinkIcon />
      </div>
      <span className="font-medium text-gray-06">{name}</span>
      <LockIcon className="ml-auto" />
    </div>
  )
}
